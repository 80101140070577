import { render, staticRenderFns } from "./phonePay.vue?vue&type=template&id=42926160&scoped=true"
import script from "./phonePay.vue?vue&type=script&lang=js"
export * from "./phonePay.vue?vue&type=script&lang=js"
import style0 from "./phonePay.vue?vue&type=style&index=0&id=42926160&prod&scoped=scoped&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42926160",
  null
  
)

export default component.exports